<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-row>
      <v-col cols="6">
        <div
          class="statistics-box d-flex dt-main-info flex-wrap"
          :class="{'blocked-box' : blocked_visibility}"
        >
          <v-col cols="6">
            <label>
              1 Декларация
              <div class="d-flex">
                <v-text-field
                  value="ДТ"
                  outlined
                  hide-details="auto"
                  dense
                  disabled
                  background-color="grey lighten-2"
                  class="mr-1 p-0 "
                />
                <v-autocomplete
                  ref="declaration_kind_code"
                  v-model="type_declaration.declaration_kind_code"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  :items="['ИМ', 'ЭК']"
                  class="mr-1 p-0"
                  @change="kindChange"
                />
                <v-autocomplete
                  ref="customs_procedure"
                  v-model="type_declaration.customs_procedure"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  :items="filteredProcedures"
                  item-text="text"
                  item-value="code"
                  class="mr-1 p-0"
                />
                <v-autocomplete
                  ref="previous_customs_procedure"
                  v-model="type_declaration.previous_customs_procedure"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  :items="customsProcedures"
                  item-text="text"
                  item-value="code"
                  class="mr-1 p-0"
                />
              </div>
            </label>
          </v-col>
          <v-col
            cols="2"
          >
            <label>
              Формы
              <div class="d-flex">
                <v-text-field
                  :value="additionalPages"
                  outlined
                  hide-details="auto"
                  dense
                  class="mr-1 p-0 "
                  :disabled="true"
                  :background-color="false ? 'white' : 'grey lighten-2'"
                />
              </div>
            </label>
          </v-col>
          <v-col
            cols="4"
          >
            <label>
              Отгрузочные спецификации
              <div class="d-flex">
                <v-text-field
                  ref="correction_reg_num"
                  v-model="type_declaration.correction_reg_num"
                  v-mask="'#####/######/У######'"
                  class="mr-1"
                  outlined
                  dense
                  background-color="grey lighten-2"
                  hide-details="auto"
                  disabled
                />
              </div>
            </label>
          </v-col>
          <v-col
            class="pt-0"
            cols="4"
          >
            <label>
              7 Код особенности декл.
              <div class="d-flex">
                <v-autocomplete
                  ref="declaration_feature"
                  v-model="type_declaration.declaration_feature"
                  :items="declarationFeatures"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  item-text="code"
                  item-value="code"
                  class="mr-1 p-0"
                  :filter="filterBySearchField"
                />
              </div>
            </label>
          </v-col>
          <v-col
            class="pt-0"
            cols="4"
          >
            <label>
              Дата ВДТ
              <div class="d-flex">
                <custom-date-picker
                  ref="reg_date_b"
                  v-model="registration_details.reg_date_b"
                  :disabled="!type_declaration.declaration_feature"
                  :background-color="disabled ? 'grey lighten-2' : 'white'"
                  :visited.sync="fields_been_visited"
                  :has-changes.sync="hasChanges"
                />
              </div>
            </label>
          </v-col>
        </div>
      </v-col>

      <v-col cols="6">
        <div class="statistics-box d-flex flex-wrap">
          <v-col
            cols="6"
          >
            <label>
              А Регистрационный номер
              <v-menu offset-x>
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="mx-1"
                    tabindex="-1"
                    small
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >mdi-tune</v-icon>
                </template>
                <v-list>
                  <v-list-item
                    @click="disabled = !disabled"
                  >Ручное заполнение</v-list-item>
                </v-list>
              </v-menu>
              <v-text-field
                ref="reg_num_a"
                v-model="registration_details.reg_num_a"
                v-mask="'#####/######/#######'"
                :background-color="disabled ? 'grey lighten-2' : 'white'"
                :disabled="disabled"
                outlined
                dense
                hide-details="auto"
              />
            </label>
          </v-col>
          <v-col
            cols="6"
          >
            <label>
              В  Номер разрешения
            </label>
            <v-text-field
              ref="reg_num_b"
              v-model="registration_details.reg_num_b"
              v-mask="'#####/########'"
              class="mr-1"
              :background-color="disabled ? 'grey lighten-2' : 'white'"
              outlined
              dense
              :disabled="disabled"
              hide-details="auto"
            />
          </v-col>
          <v-col
            cols="6"
            class="pt-0"
          >
            <label>
              Дата
            </label>
            <custom-date-picker
              ref="reg_date_b"
              v-model="registration_details.reg_date_b"
              :background-color="disabled ? 'grey lighten-2' : 'white'"
              :disabled="disabled"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
            />
          </v-col>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import CustomDatePicker from '@/components/shared/custom-date-picker.vue'
import { eventBus } from "@/bus.js";
import blockVisibility from '@/mixins/block-visibility'
import {type_declaration as onTypeDeclaration} from "@/events/statistics/control";
import {highlightField} from "@/helpers/control";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";

export default {
  components: {CustomDatePicker},
  mixins: [blockAutoUpdate, blockVisibility, filterBySearchFieldMixin],
  data() {
    return {
      date: null,
      block_id: 'dt-main-info',
      disabled:true,
      type_declaration: {
        declaration_id: null,
        customs_procedure: "",
        declaration_kind_code: "",
        previous_customs_procedure: "",
        declaration_feature: "",
      },
      registration_details: {
        declaration_id: null,
        reg_num_a: '',
        reg_num_b: '',
        reg_date_b: ''
      },
    }
  },
  computed: {
    ...mapGetters({
      selected: 'dt/getSelected',
      selected_wares: "dt/getSelectedWares",
      customsProcedures: "catalogs/getNsiCustomsProcedures",
      declarationFeatures: "catalogs/getNsiDeclarationFeatures"
    }),
    quantity() {
      return this.selected_wares?.length || 0;
    },
    additionalPages() {
      return this.quantity <= 1 ? 1 : Math.ceil((this.quantity - 1) / 3) + 1
    },
    filteredProcedures(){
      if(this.type_declaration.declaration_kind_code === 'ЭК'){
        return this.customsProcedures.filter(i => i.direction === 0)
      }else{
        return this.customsProcedures.filter(i => i.direction === 1)
      }
    }
  },
  watch: {
    selected() {
      this.setFields();
    },
  },
  created(){
    onTypeDeclaration.subscribe(this.highlightField)
  },
  beforeDestroy() {
    onTypeDeclaration.unsubscribe()
  },
  methods: {
    highlightField,
    kindChange(){
      this.type_declaration.customs_procedure = null
      eventBus.$emit("clearTransports");
    },
    setFields() {
      const { type_declaration } = this.selected || {};
      const { registration_details } = this.selected || {};
      for (let key in type_declaration) {
        if (key in this.type_declaration) {
          this.type_declaration[key] = type_declaration[key];
        }
      }
      for (let key in registration_details) {
        if (key in this.registration_details) {
          this.registration_details[key] = registration_details[key];
        }
      }
      this.setHasChangesFalse();
    },
    isKindOrProcedureChanged(){
      return this.type_declaration.customs_procedure !== this.selected.type_declaration.customs_procedure
          || this.type_declaration.declaration_kind_code !== this.selected.type_declaration.declaration_kind_code
    },
    clearAllPayments(){
      this.$store.dispatch('dt/clearPayments')
        .then(() => this.$info('Очищены платежи'))
        .catch(() => this.$error('Не удалось очистить платежи'))
    },
    async uploadData(){
      const type_declaration = this.convertEmptyStringsToNull(this.type_declaration);
      const registration_details = this.convertEmptyStringsToNull(this.registration_details)

      const kindOrProcedureChanged = this.isKindOrProcedureChanged()

      const typeDeclarationResponse = await this.$store.dispatch("dt/uploadBlockData", {
        name: "type_declaration",
        value: type_declaration,
      });

      await this.$store.dispatch("dt/uploadBlockData", {
        name: "registration_details",
        value: registration_details,
      });

      if(kindOrProcedureChanged){
        this.clearAllPayments()
      }

      this.disabled = true
      return Promise.resolve(typeDeclarationResponse);
    }
  }
}
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row class="my-auto mx-0 statistics-box">
          <v-col
            :id="block_id"
            v-click-outside="triggerOnFocus"
            cols="6"
          >
            <div class="mb-4">
              <v-tooltip
                v-for="(btn, idx) in visibleActions"
                :key="idx"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="mr-2"
                    min-width="36px"
                    max-width="36px"
                    v-bind="attrs"
                    :disabled="btn.loading"
                    :loading="btn.loading"
                    v-on="on"
                    @click="callFunction(btn.action, { action: btn.action })"
                  >
                    <v-icon :color="btn.color || ''">
                      {{ btn.icon }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ btn.name }}</span>
              </v-tooltip>
              <dts-list />
            </div>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  ref="out_number"
                  v-model="about_declaration.out_number"
                  :error="about_declaration.out_number?.length > 50"
                  maxlength="50"
                  outlined
                  dense
                  hide-details="auto"
                  :background-color="disabledView ?'grey lighten-2': 'white' "
                  :disabled="disabledView"
                />
              </v-col>
              <p class="slash mb-0 py-4">
                /
              </p>
              <v-col>
                <v-text-field
                  ref="remark"
                  v-model="about_declaration.remark"
                  outlined
                  dense
                  hide-details="auto"
                  class="remark"
                  background-color="white"
                  placeholder="Примечание"
                />
              </v-col>
              <v-col
                cols="3"
                class="pb-0"
              >
                <custom-date-picker
                  ref="declaration_date"
                  v-model="about_declaration.declaration_date"
                  :visited.sync="fields_been_visited"
                  :has-changes.sync="hasChanges"
                  class="pr-1"
                  dense
                  :background-color="disabledView ?'grey lighten-2': 'white' "
                  :disabled="disabledView"
                  @handleChange="suggestRecalc"
                />
              </v-col>
            </v-row>
          </v-col>
          <document-control
            v-if="!pinned.includes('controls')"
            class="ml-2"
            source="dt/getControl"
            pin-name="controls"
          >
            <template slot="action">
              <common-errors-action />
            </template>
          </document-control>
        </v-row>
      </v-col>
    </v-row>
    <recalculate-suggestion :show.sync="showSuggestion" />
    <component
      :is="activeComponent"
      :ref="getReferenceAsync"
      :show.sync="showComponent"
      :selected="selected"
      :params="activeComponentParams"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import CustomDatePicker from "@/components/shared/custom-date-picker"
import CommonErrorsAction from "@/components/dt/controls/common-errors-action.vue"
import DocumentControl from "@/components/shared/document-control.vue"
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import {
  displayControlResponse, downloadFile,
  downloadXml,
  getFilenameFromContentDisposition,
  getSignedDocument, getXmlString,
  NAMESPACE_REQUIRED, performDocumentControl,
  PTO_REQUIRED,
  SELECTED_REQUIRED, sendToEclient,
  USER_REQUIRED, validateRequiredParams,
} from "@/helpers/documents";
import { convertEmptyStringsToNull } from "@/helpers/objects"
import ToolboxMixin from "@/mixins/toolbox.mixin";
import {about_declaration as onAboutDeclaration} from "@/events/statistics/control";
import RecalculateSuggestion from "@/components/dt//recalculate-suggestion.vue";
import DtsList from "@/components/dt/dts-list.vue";
export default {
  components: {
    RecalculateSuggestion,
    DocumentControl,
    CustomDatePicker,
    CommonErrorsAction,
    DtsList
  },
  mixins: [blockAutoUpdate, ToolboxMixin],
  data() {
    return {
      block_id: "dt-toolbox",
      selectedPto:null,
      namespace:'dt',
      showSuggestion:false,
      about_declaration: {
        declaration_date: "",
        declaration_id: null,
        out_number: "",
        remark: "",
      },
      actions: [
        {
          visible: true,
          loading: false,
          icon: "mdi-upload",
          action: "sendDocument",
          name: "Отправить в ИСТО",
        },
        {
          visible: true,
          loading: false,
          icon: "mdi-file-pdf-box",
          action: "downloadPdf",
          name: "Скачать PDF",
        },
        {
          visible: true,
          loading: false,
          icon: "mdi-file-xml-box",
          action: "downloadXml",
          name: "Скачать XML",
        },
        {
          visible: false,
          icon: "",
          action: "showDocumentControlResponse",
          name: "Контроль FLK",
          component: () =>
            import("@/components/shared/document-control-response.vue"),
        },
        {
          visible: false,
          icon: "",
          action: "showXsdControlResponse",
          name: "Контроль XSD",
          component: () =>
            import("@/components/documents/xsd-control-response.vue"),
        },
        {
          visible: false,
          icon: "",
          action: "showDocumentControlPto",
          name: "Контроль ПТО",
          component: () => import("@/components/shared/document-control-pto.vue"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      selected: "dt/getSelected",
      pinned: "ui/getPinned",
      user: "auth/getUser",
      requests:"dt/getRequests",
      disabledView:"dt/getVisibility"
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  created() {
    onAboutDeclaration.subscribe(this.highlightField);
  },
  beforeDestroy() {
    onAboutDeclaration.unsubscribe();
  },
  methods: {
    validateRequiredParams,
    performDocumentControl,
    displayControlResponse,
    getXmlString,
    getSignedDocument,
    sendToEclient,
    sendDocument(){
      this.setLoading('sendDocument', true)
      this.validateRequiredParams({
        ...SELECTED_REQUIRED,
        ...NAMESPACE_REQUIRED,
        ...USER_REQUIRED,
        ...PTO_REQUIRED
      })
        .then(this.performDocumentControl)
        .then(this.displayControlResponse)
        .then(this.showDocumentControlPto)
        .then(this.updateSelectedPto)
        .then(this.getXmlString)
        .then(this.getSignedDocument)
        .then(this.sendToEclient)
        .catch(() => {})
        .finally(() => this.setLoading('sendDocument', false))
    },
    suggestRecalc() {
      this.showSuggestion = true
    },
    // highlightField,
    setFields() {
      const about_declaration = this.selected.about_declaration;

      Object.keys(this.about_declaration).forEach((key) => {
        if (key in about_declaration) {
          this.about_declaration[key] = about_declaration[key];
        }
      });
    },
    downloadXml() {
      this.$store.dispatch("dt/getXml", this.selected.id).then((res) => {
        downloadXml(res.data, getFilenameFromContentDisposition(res));
      });
    },
    downloadPdf() {
      this.$store.dispatch('dt/downloadPdf', this.selected.id).then((res) => {
        const name = getFilenameFromContentDisposition(res)
        downloadFile({data: res.data, type: 'application/pdf', name})
      }).catch(() => this.$error())
    },
    openDts(id) {
      const route = this.$router.resolve({
        path: `/dts/${id}`,
      });
      window.open(route.href, "_blank");
    },
    uploadData() {
      const about_declaration = convertEmptyStringsToNull({
        ...this.about_declaration,
      });

      return this.$store.dispatch("dt/uploadBlockData", {
        name: "about_declaration",
        value: about_declaration,
      });
    },
  },
};
</script>
